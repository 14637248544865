import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { useNavigate } from 'react-router-dom';
import postMethod from '../components/hooks/httpMethods/postMethod'; 
import { ToastContainer, toast } from 'react-toastify'; // Import ToastContainer and toast
import 'react-toastify/dist/ReactToastify.css'; // Import Toast CSS

function Register() {
  const navigate = useNavigate();
  const { handleLogin, loading } = postMethod(); 
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [user_type] = useState('admin'); 
  const url = 'http://127.0.0.1:5000/register'; 

  const loginClick = (event) => {
    event.preventDefault();
    navigate('/'); 
  };

  const onSubmit = (e) => {
    e.preventDefault();
    
    const inputJson = { name, email, password, user_type }; 
    console.log(inputJson);

    handleLogin(
      url,
      inputJson,
      (data) => {
        toast.success(data.message, {
          autoClose: 2000,
        });
        console.log('Register successful:', data);
        setTimeout(() => {
            navigate('/'); // Redirect to login after toast
          }, 2000);
      },
      (error) => {
        console.error('Register failed:', error.response?.data?.message || error.message);
        toast.error((error.response?.data?.message || 'Invalid credentials')); // Show toast notification
      }
    );
  };

  return (
    <div className="hold-transition register-page">
         <ToastContainer />
      <div className="register-box">
        <div className="register-logo">
          <a href="../../index2.html"><b>Admin</b>LTE</a>
        </div>
  
        <div className="card">
          <div className="card-body register-card-body">
            <p className="login-box-msg">Register a new membership</p>
  
            <form onSubmit={onSubmit}>
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Full name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-user"></span>
                  </div>
                </div>
              </div>
              <div className="input-group mb-3">
                <input
                  type="email"
                  className="form-control"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-envelope"></span>
                  </div>
                </div>
              </div>
              <div className="input-group mb-3">
                <input
                  type="password"
                  className="form-control"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-lock"></span>
                  </div>
                </div>
              </div>
              <div className="input-group mb-3">
                <input
                  type="password"
                  className="form-control"
                  placeholder="Retype password"
                  required
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-lock"></span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-8">
                  <div className="icheck-primary">
                    <input type="checkbox" id="agreeTerms" name="terms" value="agree"/>
                    <label htmlFor="agreeTerms">
                      I agree to the <a href="#">terms</a>
                    </label>
                  </div>
                </div>
                <div className="col-4">
                  <button type="submit" className="btn btn-primary btn-block" disabled={loading}>
                    {loading ? 'Registering...' : 'Register'}
                  </button>
                </div>
              </div>
            </form>
  
            <a href="#" onClick={loginClick} className="text-center">I already have a membership</a>
          </div>
        </div> 
      </div>
    </div>
  );
}

export default Register;
