import { useState } from 'react';
import axios from 'axios';

const PostMethod = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleLogin = async (url, inputJson, onSuccess, onFailure) => {
    setLoading(true);
    setError(null);

    try {
      const response = await axios.post(
        url,
        inputJson, // Use the credentials object directly
        {
          headers: {
            'Accept': 'application/json;odata=verbose',
            'Content-Type': 'application/json;odata=verbose',
          },
        }
      );

      if (response) {
        if (onSuccess) onSuccess(response.data); // Pass only the response data
      }
    } catch (error) {
      setError(error);
      if (onFailure) onFailure(error); // Call the failure callback with the error
    } finally {
      setLoading(false);
    }
  };

  return { handleLogin, loading, error };
};

export default PostMethod;
