import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { useNavigate } from 'react-router-dom';
import postMethod from '../components/hooks/httpMethods/postMethod'; // Make sure to adjust the import path
import { ToastContainer, toast } from 'react-toastify'; // Import ToastContainer and toast
import 'react-toastify/dist/ReactToastify.css'; // Import the CSS for styling

function Login() {
  const navigate = useNavigate();
  const { handleLogin, loading } = postMethod(); // Use the custom hook
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [url] = useState('http://127.0.0.1:5000/login'); // Set your desired URL

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      navigate('/dashboard'); // Redirect to dashboard if token exists
    }
  }, [navigate]);

  const registerClick = (event) => {
    event.preventDefault();
    navigate('/register');
  };

  const onSubmit = (e) => {
    e.preventDefault();
    
    // Create credentials object
    const inputJson = { email, password }; 

    handleLogin(
      url,
      inputJson, // Pass the credentials object
      (data) => {
        // Handle successful login
        console.log('Login successful:', data);
        localStorage.setItem('token', data.token); // Store token if available
        navigate('/dashboard'); // Redirect to dashboard
      },
      (error) => {
        // Handle login failure
        console.error('Login failed:', error.response?.data?.message || error.message);
        toast.error('Login failed: ' + (error.response?.data?.message || 'Invalid credentials')); // Show toast notification
      }
    );
  };

  return (
    <div className="hold-transition login-page">
      <ToastContainer /> {/* Add ToastContainer to the JSX */}
      <div className="login-box">
        <div className="login-logo">
          <a href="#"><b>Admin</b>LTE</a>
        </div>
        <div className="card">
          <div className="card-body login-card-body">
            <p className="login-box-msg">Sign in to start your session</p>
            <form onSubmit={onSubmit}>
              <div className="input-group mb-3">
                <input
                  type="email"
                  className="form-control"
                  placeholder="Email"
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-envelope"></span>
                  </div>
                </div>
              </div>
              <div className="input-group mb-3">
                <input
                  type="password"
                  className="form-control"
                  placeholder="Password"
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-lock"></span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-8">
                  <div className="icheck-primary">
                    <input type="checkbox" id="remember" />
                    <label htmlFor="remember"> Remember Me </label>
                  </div>
                </div>
                <div className="col-4">
                  <button type="submit" className="btn btn-primary btn-block" disabled={loading}>
                    {loading ? 'Signing In...' : 'Sign In'}
                  </button>
                </div>
              </div>
            </form>

            <p className="mb-1">
              <a href="#">I forgot my password</a>
            </p>
            <p className="mb-0">
              <a href="#" onClick={registerClick} className="text-center">Register a new membership</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
